import { IContactPhoneBE } from "@datalex-software-as/datalex-client"
import { EmptyGuid } from "./RandomId"

interface INewContactPhoneArgs {
    Name: string,
    Id: string
}

export function NewContactPhone({Id, Name }: INewContactPhoneArgs): IContactPhoneBE {
    return {
        IsNew: true,
        IsDeleted: false,
        Id: EmptyGuid,
        ContactId: EmptyGuid,
        PhoneTypeId: Id,
        PhoneType: Name,
        Number: "",
        CountryCode: "",
        Extension: null,
        Timestamp: []
    }
}

export function CompareContactPhones(a: IContactPhoneBE[], b: IContactPhoneBE[]): boolean {
    return (JSON.stringify(a) === JSON.stringify(b));
}

