import { Injectable } from '@angular/core';
import { DatalexClient, IBusinessSectorBE, ICaseLimitedBE, IContactExtendedBE, IContactPhoneBE, ICountryBE, IUserLimitedBE } from '@datalex-software-as/datalex-client';
import { CountryService, ICountry } from 'src/app/services/country.service';
import { CompareContactPhones } from 'src/app/util/ContactPhoneUtil';
import { ContactSectorIdEnum, ContactSectorsArray } from 'src/app/util/ContactSectorUtil';
import { toLocalIsoString } from 'src/app/util/DatalexDateTime';
import { OrganisationalStructures } from 'src/app/util/OrganisationalStructureUtil';
import { CompareCode } from 'src/app/util/SortCompare';



@Injectable({
  providedIn: 'root'
})
export class ContactDataService {

  constructor(private dlxClient: DatalexClient, private countryData: CountryService) { }


  // @Output() notifyChange: EventEmitter<void> = new EventEmitter()

  // select datasources
  allEmployees!: IUserLimitedBE[];
  countries: ICountryBE[] = this.countryData.allCountries
  contactSectors = ContactSectorsArray.filter(s => s.Id !== ContactSectorIdEnum.M);
  organisationalStructures = OrganisationalStructures.sort(CompareCode);
  businessSectors!: IBusinessSectorBE[];

  onContactSectorChange(event: string) {
    this.contactSectorId = event;
    this.contactSectorCode = this.contactSectors.filter(s => s.Id.toLocaleUpperCase() === event.toLocaleUpperCase())[0].Code;
    this.setIsCompany(event)
  }
  onContactSectorReset() {
    this.contactSectorId = this.contact.ContactSectorId;
  }


  public contact!: IContactExtendedBE;
  public contactCases!: ICaseLimitedBE[];

  contactNumber!: number;
  contactSectorCode!: string;
  contactSectorId!: string | null;
  responsibleUserId!: string | null;
  contactFirstName!: string;
  contactLastName!: string;
  contactName!: string;
  contactName2!: string;
  contactPsuedoName!: string;
  contactOccupation!: string;
  contactTitle!: string;
  contactShortForm!: string;
  contactCountry!: string | null;
  contactCountryId!: string | null;
  contactNationalIdentityNumber!: string;
  contactDateOfBirth!: string | null;
  contactOrganizationNumber!: string;
  contactPublicCompany!: boolean;
  contactBusinessSectorId!: string;
  contactOrganizationalStructureId!: string | null;
  contactEmail!: string | null
  contactEmailAddressInvoice!: string | null;
  contactWebAddress!: string | null;
  contactPhones!: IContactPhoneBE[];
  displayDayOfBirth!: string;
  isCompany: boolean | null = false;





  setContact(contactId: string) {
    this.dlxClient.GetContactExtendedById(contactId).subscribe({
      next: (contact) => {
        this.dlxClient.GetContactCases(contact.Id).subscribe({
          next: (contactCases) => {
            this.contact = contact;
            this.contactCases = contactCases;
            this.componentInit()
          }
        })
      }
    })
  }

  setIsCompany(contactSectorId: string | null): void {

    switch (contactSectorId) {
      case ContactSectorIdEnum.P:
      case ContactSectorIdEnum.S:
        this.isCompany = false;
        break;
      default:
        this.isCompany = true;
        break;
    }
  }

  onDateChange() {
    this.contactDateOfBirth = toLocalIsoString(this.displayDayOfBirth, true);
  }


  componentInit(contact = this.contact) {
    this.contactNumber = contact.Number;
    this.contactSectorCode = contact.ContactSectorCode;
    this.contactSectorId = contact.ContactSectorId;
    this.responsibleUserId = contact.ResponsibleUserId;
    this.contactFirstName = contact.FirstName;
    this.contactLastName = contact.LastName;
    this.contactName = contact.Name;
    this.contactName2 = contact.Name2;
    this.contactPsuedoName = contact.PseudoName;
    this.contactOccupation = contact.Occupation;
    this.contactTitle = contact.Title;
    this.contactShortForm = contact.Shortform;
    this.contactCountryId = contact.CountryId;
    this.contactCountry = contact.Country;
    this.contactNationalIdentityNumber = contact.NationalIdentityNumber;
    this.contactDateOfBirth = contact.DateOfBirth;
    this.contactOrganizationNumber = contact.OrganizationNumber;
    this.contactPublicCompany = contact.PublicCompany;
    this.isCompany = contact.IsCompany;
    this.contactOrganizationalStructureId = contact.OrganizationalStructureId;
    this.contactEmail = contact.EmailAddress;
    this.contactEmailAddressInvoice = contact.EmailAddressInvoice;
    this.contactWebAddress = contact.WebAddress;
    this.contactPhones = contact.ContactPhones;
    this.displayDayOfBirth = contact.DateOfBirth ? formatDate2(new Date(contact.DateOfBirth)) : "";
  }

  isChanged() {
    if (this.contactNumber === this.contact.Number &&
      this.contactSectorCode === this.contact.ContactSectorCode &&
      this.contactSectorId === this.contact.ContactSectorId &&
      this.responsibleUserId === this.contact.ResponsibleUserId &&
      this.contactFirstName === this.contact.FirstName &&
      this.contactLastName === this.contact.LastName &&
      this.contactName === this.contact.Name &&
      this.contactName2 === this.contact.Name2 &&
      this.contactPsuedoName === this.contact.PseudoName &&
      this.contactOccupation === this.contact.Occupation &&
      this.contactTitle === this.contact.Title &&
      this.contactShortForm === this.contact.Shortform &&
      this.contactCountryId === this.contact.CountryId &&
      this.contactCountry === this.contact.Country &&
      this.contactNationalIdentityNumber === this.contact.NationalIdentityNumber &&
      this.contactDateOfBirth === this.contact.DateOfBirth &&
      this.contactOrganizationNumber === this.contact.OrganizationNumber &&
      this.contactPublicCompany === this.contact.PublicCompany &&
      this.contactOrganizationalStructureId === this.contact.OrganizationalStructureId &&
      this.contactEmail === this.contact.EmailAddress &&
      this.contactEmailAddressInvoice === this.contact.EmailAddressInvoice &&
      this.contactWebAddress === this.contact.WebAddress &&
      CompareContactPhones(this.contactPhones, this.contact.ContactPhones)) {
      return false;
    }
    return !(!!this.contactNumber === !!this.contact.Number &&
      !!this.contactSectorCode === !!this.contact.ContactSectorCode &&
      !!this.contactSectorId === !!this.contact.ContactSectorId &&
      !!this.responsibleUserId === !!this.contact.ResponsibleUserId &&
      !!this.contactFirstName === !!this.contact.FirstName &&
      !!this.contactLastName === !!this.contact.LastName &&
      !!this.contactName === !!this.contact.Name &&
      !!this.contactName2 === !!this.contact.Name2 &&
      !!this.contactPsuedoName === !!this.contact.PseudoName &&
      !!this.contactOccupation === !!this.contact.Occupation &&
      !!this.contactTitle === !!this.contact.Title &&
      !!this.contactShortForm === !!this.contact.Shortform &&
      !!this.contactCountryId === !!this.contact.CountryId &&
      !!this.contactCountry === !!this.contact.Country &&
      !!this.contactNationalIdentityNumber === !!this.contact.NationalIdentityNumber &&
      !!this.contactDateOfBirth === !!this.contact.DateOfBirth &&
      !!this.contactOrganizationNumber === !!this.contact.PublicCompany &&
      !!this.contactPublicCompany === !!this.contact.PublicCompany &&
      !!this.contactOrganizationalStructureId === !!this.contact.OrganizationalStructureId &&
      !!this.contactEmail === !!this.contact.EmailAddress &&
      !!this.contactEmailAddressInvoice === !!this.contact.EmailAddressInvoice &&
      !!this.contactWebAddress === !!this.contact.WebAddress &&
      !!this.contactPhones === !!this.contact.ContactPhones);
  }

  save() {
    let be = this.contact;
    be.ContactSectorCode = this.contactSectorCode;
    be.ContactSectorId = this.contactSectorId;
    be.FirstName = this.contactFirstName;
    be.LastName = this.contactLastName;
    be.Name = this.contactName;
    be.Name2 = this.contactName2;
    be.PseudoName = this.contactPsuedoName;
    be.Occupation = this.contactOccupation;
    be.Title = this.contactTitle;
    be.Shortform = this.contactShortForm;
    be.CountryId = this.contactCountryId;
    be.Country = this.contactCountry ?? "";
    be.NationalIdentityNumber = this.contactNationalIdentityNumber;
    be.DateOfBirth = this.contactDateOfBirth;
    be.OrganizationNumber = this.contactOrganizationNumber;
    be.PublicCompany = this.contactPublicCompany;
    be.OrganizationalStructureId = this.contactOrganizationalStructureId;
    be.EmailAddress === this.contactEmail;
    be.EmailAddressInvoice === this.contactEmailAddressInvoice;
    be.WebAddress === this.contactWebAddress;
    be.ContactPhones === this.contactPhones;
  }

}



function formatDate2(date: Date): string {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}
