export const OrganisationalStructures = [
  {
    "Id": "21321311-0000-0000-0000-000000000010",
    "Name": "Enkeltpersonforetak",
    "Order": 1,
    "Active": true,
    "Code": "EM"
  },
  {
    "Id": "21321311-0000-0000-0000-000000000011",
    "Name": "Ansvarlig selskap (ANS)",
    "Order": 2,
    "Active": true,
    "Code": "ANS"
  },
  {
    "Id": "21321311-0000-0000-0000-000000000012",
    "Name": "Delt ansvar (DA)",
    "Order": 3,
    "Active": true,
    "Code": "DA"
  },
  {
    "Id": "21321311-0000-0000-0000-000000000013",
    "Name": "Aksjeselskap (AS)",
    "Order": 4,
    "Active": true,
    "Code": "AS"
  },
  {
    "Id": "21321311-0000-0000-0000-000000000014",
    "Name": "Allment aksjeselskap (ASA)",
    "Order": 5,
    "Active": true,
    "Code": "ASA"
  },
  {
    "Id": "21321311-0000-0000-0000-000000000015",
    "Name": "Konsern",
    "Order": 6,
    "Active": true,
    "Code": "KON"
  },
  {
    "Id": "21321311-0000-0000-0000-000000000016",
    "Name": "Kommandittselskap",
    "Order": 7,
    "Active": true,
    "Code": "KOM"
  },
  {
    "Id": "21321311-0000-0000-0000-000000000017",
    "Name": "Stiftelse",
    "Order": 8,
    "Active": true,
    "Code": "ST"
  },
  {
    "Id": "21321311-0000-0000-0000-000000000018",
    "Name": "Samvirkeselskap\/andelslag (BA)",
    "Order": 9,
    "Active": true,
    "Code": "BA"
  },
  {
    "Id": "21321311-0000-0000-0000-000000000019",
    "Name": "Europeisk selskap",
    "Order": 10,
    "Active": true,
    "Code": "SE"
  },
  {
    "Id": "21321311-0000-0000-0000-000000000020",
    "Name": "Fylkeskommunalt foretak",
    "Order": 11,
    "Active": true,
    "Code": "FKF"
  },
  {
    "Id": "21321311-0000-0000-0000-000000000021",
    "Name": "Kommunalt foretak",
    "Order": 12,
    "Active": true,
    "Code": "KF"
  },
  {
    "Id": "21321311-0000-0000-0000-000000000022",
    "Name": "Indre selskap",
    "Order": 13,
    "Active": true,
    "Code": "IS"
  },
  {
    "Id": "21321311-0000-0000-0000-000000000023",
    "Name": "Interkommunalt selskap",
    "Order": 14,
    "Active": true,
    "Code": "IKS"
  },
  {
    "Id": "21321311-0000-0000-0000-000000000024",
    "Name": "Samvirkeforetak",
    "Order": 15,
    "Active": true,
    "Code": "SA"
  },
  {
    "Id": "21321311-0000-0000-0000-000000000025",
    "Name": "Gjensidig forsikringsselskap",
    "Order": 16,
    "Active": true,
    "Code": "GFS"
  },
  {
    "Id": "21321311-0000-0000-0000-000000000026",
    "Name": "Statsforetak",
    "Order": 17,
    "Active": true,
    "Code": "SF"
  },
  {
    "Id": "21321311-0000-0000-0000-000000000027",
    "Name": "Norskregistrert Utenlandsk Foretak",
    "Order": 18,
    "Active": true,
    "Code": "NUF"
  },
  {
    "Id": "21321311-0000-0000-0000-000000000028",
    "Name": "Forening",
    "Order": 19,
    "Active": true,
    "Code": "FO"
  },
  {
    "Id": "21321311-0000-0000-0000-000000000029",
    "Name": "Eierseksjonssameie",
    "Order": 20,
    "Active": true,
    "Code": "ESS"
  },
  {
    "Id": "21321311-0000-0000-0000-00000000002A",
    "Name": "Borettslag",
    "Order": 21,
    "Active": true,
    "Code": "BRL"
  },
  {
    "Id": "21321311-0000-0000-0000-00000000002B",
    "Name": "Boligaksjeselskap",
    "Order": 22,
    "Active": true,
    "Code": "BAS"
  }
]


export enum OrganisationalStructureEnum {

  EM = "21321311-0000-0000-0000-000000000010",
  ANS = "21321311-0000-0000-0000-000000000011",
  DA = "21321311-0000-0000-0000-000000000012",
  AS = "21321311-0000-0000-0000-000000000013",
  ASA = "21321311-0000-0000-0000-000000000014",
  KON = "21321311-0000-0000-0000-000000000015",
  KOM = "21321311-0000-0000-0000-000000000016",
  ST = "21321311-0000-0000-0000-000000000017",
  BA = "21321311-0000-0000-0000-000000000018",
  SE = "21321311-0000-0000-0000-000000000019",
  FKF = "21321311-0000-0000-0000-000000000020",
  KF = "21321311-0000-0000-0000-000000000021",
  IS = "21321311-0000-0000-0000-000000000022",
  IKS = "21321311-0000-0000-0000-000000000023",
  SA = "21321311-0000-0000-0000-000000000024",
  GFS = "21321311-0000-0000-0000-000000000025",
  SF = "21321311-0000-0000-0000-000000000026",
  NUF = "21321311-0000-0000-0000-000000000027",
  FO = "21321311-0000-0000-0000-000000000028",
  ESS = "21321311-0000-0000-0000-000000000029",
  BRL = "21321311-0000-0000-0000-00000000002A",
  BAS = "21321311-0000-0000-0000-00000000002B"
}